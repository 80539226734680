<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="UserEditModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="UserEditModal"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="container p-3">
            <div class="row mb-4">
              <div
                class="d-flex col-12 justify-content-between align-items-center"
              >
                <h4 class="modal-heading">Change account details</h4>
                <button @click="closeModal" class="btn" style="">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <form action="" @submit.prevent="submitUserDetail()" :class="[isSubmit?'pointer-event-none':'']">
            <div class="row">
             
              <b-col cols="12" class="d-flex justify-content-center">
                 <div v-if="isImageUpdating" style="height: 120px; width: 120px;border-radius:50%;background:rgba(0,0,0, 0.1);" class="d-flex align-items-center justify-content-center">
  <b-spinner label="Spinning" style="color:#fff;"></b-spinner>
                </div>
                <UploadImage v-else imageFile="" ref="uploadImage" :isUpload="true" :text="detail.name" :uploadImage="user.image" @change="changeImage"/>
               
              </b-col>
              <b-col cols="12" class="d-flex justify-content-center">
                <div
                  class="upload-image text-center mt-2"
                  @click="triggerChangeImage"
                >
                  Change Image
                </div>
              </b-col>
            </div>
            <b-row class="px-5">
              <b-col cols="12">
                <b-form-group
                  id="user-detail"
                  label-for="user-name"
                  label="Name:"
                 
                >
                  <b-form-input
                    class="form-control"
                    id="user-name"
                    v-model="detail.name"
                    :disabled="disableInput"
                   
                    trim
                    placeholder="Name"
                  ></b-form-input>
                 
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <div class="form-group">
                <div style="font-size: 14px;
    color: #73738d;" class="d-flex justify-content-between">
                  <label for="user-email">Email</label>
                    <div class="change-detail px-2" style="cursor:pointer" @click="openEmailVerifyModal"><i class="fa-regular fa-envelope mr-1"></i>Change</div>
                  
                </div>
                  <b-form-input
                    type="email"
                    class="form-control"
                    id="user-email"
                    name="user-email"
                    :value="detail.email"
                     :disabled="true"
                    
                    trim
                    placeholder="Email"
                  ></b-form-input>
                 
                </div>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  id="user-detail"
                  label-for="user-phone;"
                  label="Phone Number:"
                >
                  <VueTelInput
                    v-model="detail.phone"
                    :inputOptions="phoneInputOption"
                    :dropdownOptions="dropdown"
                    @validate="onValidation"
                    mode="international"
                    :validCharactersOnly="true"
                  />
                  <span v-if="phoneValid&&isSubmit" class="text-danger invalid-feedback d-block mt-1 ml-2">Invalid Phone
                                            Number</span>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="d-flex justify-content-center">
                <button class="btn btn-submit" >
                  <b-spinner small label="Small Spinner" v-if="isSubmit"></b-spinner>
                  <span v-else>Save Changes</span>
</button>
              </b-col>
              
            </b-row>
          </form>
          </div>
        </div>
      </div>
    </div>
    <VerifyEmail ref="verifyEmail"  :user="user"  @UpdateAccountData="$emit('UpdateAccountData')"></VerifyEmail>
  </div>
</template>

<script>
import VerifyEmail from "./VerifyEmail.vue";
import UploadImage from "./UploadImage.vue";
import {mapGetters} from "vuex";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import globalMixin from "../../../../GlobalMixins/getSignedURLMixin.js";
import axios from 'axios'
import $ from "jquery";
export default {
  props: {
    user: Object,
  },
   mixins: [globalMixin],
  data() {
    return {
      detail: { ...this.user },
      isSubmit: false,
      phoneValid: false,
      phoneInputOption: {
        placeholder: "Enter Phone Number",
        maxlength: 15,
      },
      dropdown: {
        showSearchBox: true,
        showFlags: true,
        width: "390px",
      },
      error:{
        phone:'Please fill name field',
        email:'Please enter a valid email',
        isDuplicateEmail:false,
        name:false
      },
      isImageUpdating:false
    };
  },
watch:{
    user:{
    handler(){
      this.detail = {...this.user}
   
    },deep:true
  },
  detail:{
    handler(){
      if (this.detail.name && this.isSubmit)
        if (this.detail.name=='') {
          this.error.name = "Please fill name field.";
        }
        else if(this.detail.name.length<3)
        this.error.name = "Name must contains least 3 characters.";
        // eslint-disable-next-line no-useless-escape
        const reg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
      if (this.detail && this.isSubmit)
        if (reg.test(this.detail.email)) {
          this.error.email = "Please enter a valid email";
        }
    },
    deep:true
  }
},
  computed: {
    nameState() {
      if (this.detail.name && this.isSubmit)
        return this.detail.name.length >= 3;
      else return null;
    },

    emailState() {
      // eslint-disable-next-line no-useless-escape
      const reg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
      if(this.error.isDuplicateEmail)
      return false
      if (this.detail && this.isSubmit) return reg.test(this.detail.email);
      else return null;
    },
  validateSubmission(){
   return this.emailState&&this.nameState&&(this.phoneValid||this.detail.phone=='')
  },
  ...mapGetters( ["getNavbarData"]),
     getUserType(){
      return this.getNavbarData.userType
    },
    disableInput(){
      let allowedUsers = ['web', 'bc', 'wix' , 'wordpress']
       
       return allowedUsers.includes(this.getUserType) ? false : true

    }
  },
  methods: {
    openEmailVerifyModal(){
    this.$refs.verifyEmail.OpenModal();
    this.closeModal();
    },
    triggerChangeImage(){
      this.$refs.uploadImage.inputUploadFile()
    },
    changeImage(image){
      this.uploadOnServer(image)
    },
     uploadOnServer(file) {
      this.isImageUpdating = true;
      let validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
      ];
      try {
      
          
          let fileType = file.type;
          let fileName = file.name;
          if (validImageTypes.includes(fileType)) {
            let reader = new FileReader();
            reader.onload = async (e) => {
              let obj = {
                fileName: fileName,
                fileType: fileType,
                foderName: "profiles",
              };

              let getSignedURLResponse = await this.getSignedUrl(obj);
              if (getSignedURLResponse.data.ok) {
                let data = getSignedURLResponse.data;
                let origionalName = data.origionalName;
                let filePath = data.filePath;
                let postData = {
                  origionalName: origionalName,
                  image_path: filePath,
                };
                let digitalSpacesResponse = await this.UploadSignedURLImage(
                  data,
                  e
                );
                if (digitalSpacesResponse.ok) {
                  var elements = digitalSpacesResponse.url.split("?");
                  var fistElement = elements[0];
                  var filePortion = fistElement.split("/").pop();
                    if (postData.image_path.includes(filePortion)) {
                      try {
                       
                        //  let data = JSON.stringify(element)
                        let data = postData;
                        const finalResponse = await axios.post(
                          `${process.env.VUE_APP_API_BASE_URL}storeProfileImage`,
                          data
                        );
                        if (
                          finalResponse.status == 200 
                        ) {
                           this.$emit("UpdateUserImageData");
                           this.closeModal()
                            this.isImageUpdating = false;
                        }
                      } catch (error) {
                        if (error) {
                          this.$toasted.show("Error occured", {
                            theme: "toasted-primary",
                            position: "bottom-center",
                            duration: 2000,
                          });
                          this.isImageUpdating = false;
                          throw error;
                        }
                      }
                    }
                }
              }
            };

            // Read in the image file as a Blob.
            reader.readAsArrayBuffer(file);
          }
       

      

         
      } catch (error) {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        this.isUserUpdating = false;
        throw error;
        
      } 
      
    },
    OpenModal() {
      $("#UserEditModal").modal("show");
    },
    closeModal() {
      $("#UserEditModal").modal("hide");
    },
    onValidation(e) {
      if (e.valid !== undefined) {
        this.phoneValid = !e.valid;
      } else {
        this.phoneValid = false;
      }
    },
    async submitUserDetail(){
      this.isSubmit=true;
       try {
        let data = {
         
          name:this.detail.name,
          phone: this.detail.phone,
        };
        const response = await axios.post(`/updateUserProfile`, data);
        if (response.status == 200 ) {
          this.closeModal()
          this.$emit('UpdateAccountData')
        }
      } catch (error) {
       
        if (error) {
          this.$toasted.show(error.response.data.message, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally{
        this.isSubmit=false;
      }
      
    }
  },

  components: {
    VerifyEmail,
    UploadImage,
    VueTelInput,
  },
};
</script>
<style>
.pointer-event-none{
pointer-events: none;
}
.btn-submit {
  color: #ffffff;
  font-size: 14px;

  align-items: center;
  padding: 10px 16px;
  gap: 10px;

  width: 139px;
  height: 41px;

  background: #ffa201;
  border-radius: 8px;
}

.vue-tel-input:focus-within {
  color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
#user-detail__BV_label_ {
  /* margin:0px; */
  font-size: 14px;
  color: #73738d;
}
</style>
<style scoped>
.btn-submit:hover {
  color: #ffffff;
}
.btn-submit:active {
  background: #dd9315;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.upload-image {
  text-decoration-line: underline;
  color: #ffa201;
  font-size: 14px;
  cursor: pointer;
}
.UserEditModalStyle {
  max-height: 80vh;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}
.modal-heading {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}

.btn-profile-seeting {
  border: 1px solid #ffa201;
  color: #ffa201;
}
.btn-profile-seeting:hover {
  border: 1px solid #ffa201;
  color: #ffffff;
  background: #ffa201;
}

.TitleHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;

  color: #18191c;
}


</style>
